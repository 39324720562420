.consultation {
    padding: 10px;
    background-color: white;
}

.consultation .consultationHeading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.consultation .consultationHeading .consultationTitle {
    font-size: 3vw;
    color: black;
}

.consultation .consultationHeading .consultationTagline {
    font-size: 1.2vw;
    color: black;
}

.consultationContent {
    margin-top: 30px;
    display: grid;
    justify-content: center;
    gap: 5%;
    grid-template-columns: repeat(2, 45%); /* Two columns for larger screens */
    grid-template-rows: repeat(1, 50vh);
}

.consultationContent .card {
    display: flex;
    align-items: center;
    justify-content: center;
}

.consultationCard1 {
    background: url('../../Components/Assets/DC1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.consultationCard2 {
    background: url('../../Components/Assets/DC2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.consultationContent .card .cardInfo {
    background-color: #000000d1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 20px;
    color: white;
}

.consultationContent .card .cardInfo .cardInfoHeading {
    font-size: 2vw;
    font-weight: 600;
}

.consultationContent .card .cardInfo .cardInfoTagline {
    font-size: 1.2vw;
    text-align: center;
}

.consultationContent .card .cardInfo button {
    all: unset;
    height: 50px;
    width: 250px;
    text-align: center;
    border: 1px solid white;
    transition: all ease 0.2s;
    cursor: pointer;
}

.consultationContent .card .cardInfo button:hover {
    color: black;
    background-color: white;
}

/* Tablet Responsiveness */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .consultationContent {
        grid-template-columns: repeat(2, 1fr); /* Two columns for tablets */
        grid-template-rows: auto; /* Adjust rows based on content */
    }

    .consultation .consultationHeading .consultationTitle {
        font-size: 4vw; /* Adjust title size for tablets */
    }

    .consultation .consultationHeading .consultationTagline {
        font-size: 2vw; /* Adjust tagline size for tablets */
    }

    .consultationContent .card .cardInfo .cardInfoHeading {
        font-size: 2.5vw; /* Adjust heading size for tablets */
    }

    .consultationContent .card .cardInfo .cardInfoTagline {
        font-size: 1.5vw; /* Adjust tagline size for tablets */
    }

    .consultationContent .card .cardInfo button {
        width: 200px; /* Adjust button width for tablets */
        font-size: 2vw; /* Adjust button font size for tablets */
    }
}

/* Mobile Responsiveness */
@media screen and (max-width: 767px) {
    .consultationContent {
        grid-template-columns: 1fr; /* Single column for mobile */
        gap: 20px; /* Increase gap between cards on mobile */
    }

    .consultation .consultationHeading .consultationTitle {
        font-size: 6vw; /* Adjust title size for mobile */
    }

    .consultation .consultationHeading .consultationTagline {
        font-size: 3vw; /* Adjust tagline size for mobile */
    }

    .consultationContent .card {
        height: auto; /* Allow height to adjust based on content */
    }

    .consultationContent .card .cardInfo .cardInfoHeading {
        font-size: 5vw; /* Adjust heading size for mobile */
    }

    .consultationContent .card .cardInfo .cardInfoTagline {
        font-size: 3.5vw; /* Adjust tagline size for mobile */
    }

    .consultationContent .card .cardInfo button {
        width: 80%; /* Full width button on mobile */
        max-width: 250px; /* Max width for button */
        font-size: 3.5vw; /* Adjust button font size for mobile */
    }
}