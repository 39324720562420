.sustainHeading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sustainHeading .heading {
    padding: 15px;
    text-align: center;
    width: 100%;
    background-color: black;
    color: white;
    font-size: 3vw;
    font-weight: 700;
}

.sustainHeading .heading .colorChange {
    color: #03B350;
}

.sustainContent {
    display: flex;
    height: 38vh;
    padding: 0 25px;
}

.sustainContent .contentSection {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5%;
    gap: 15px;
}

.sustainContent .contentSection .sectionHeading {
    font-size: 2vw;
}

.sustainContent .contentSection button {
    all: unset;
    height: 50px;
    width: 250px;
    text-align: center;
    border: 1px solid black;
    color: black;
    transition: all ease 0.2s;
    cursor: pointer;
}

.sustainContent .contentSection button:hover {
    color: white;
    background-color: black;
}

.sustainContent .imgSection {
    width: 40%;
    background-color: red;
}

.sustainContent .imgSection img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/* Mobile Responsiveness */
@media screen and (max-width: 767px) {
    .sustainContent {
        flex-direction: column-reverse;
        height: auto;
        padding: 20px;
    }

    .second {
        flex-direction: column;
        height: auto;
        padding: 20px;
    }

    .sustainContent .contentSection {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }

    .sustainContent .imgSection {
        width: 100%;
        height: 200px;
    }

    .sustainHeading .heading {
        font-size: 6vw;
    }

    .sustainContent .contentSection .sectionHeading {
        font-size: 5vw;
    }

    .sustainContent .contentSection button {
        width: 100%;
        font-size: 3.5vw;
    }
}