.custom-printing .printing-heading {
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    color: #0d0d0d;
    text-align: center;
}

.custom-printing .printing-heading .printing-title {
    font-size: 3vw;
    font-weight: 700;
    margin-bottom: 10px;
}

.custom-printing .printing-heading .printing-tagline {
    font-size: 3vw;
    font-weight: 400;
}

.custom-printing .printing-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

}

.custom-printing .printing-content .card {
    border: none;
    border-radius: 0;
    position: relative;
    overflow: hidden;
    height: auto; /* Allow height to adjust based on content */
    min-height: 400px; /* Set a minimum height */
}

.custom_card1 {
    background: url('../../Components/Assets/customize1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.custom_card2 {
    background: url('../../Components/Assets/customize2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.custom_card3 {
    background: url('../../Components/Assets/customize3.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.custom_card4 {
    background: url('../../Components/Assets/customize4.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.custom_card5 {
    background: url('../../Components/Assets/customize5.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.custom_card6 {
    background: url('../../Components/Assets/customize6.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.custom_card7 {
    background: url('../../Components/Assets/customize9.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.custom_card8 {
    background: url('../../Components/Assets/customize8.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.custom-printing .printing-content .card .card-info {
    background-color: rgba(13, 13, 13, 0.75);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    transition: all ease 0.3s;
    padding: 10px;
    height: 25%;
}

.custom-printing .printing-content .card .card-info h3 {
    font-size: 1vw; /* Responsive size */
    margin-bottom: 5px;
    transition: all ease 0.3s;
}

.custom-printing .printing-content .card .card-info ul {
    display: none;
    flex-direction: column;
    font-size: 1vw; /* Responsive font size for list items */
    padding: 0;
    list-style-type: none;
    opacity: 0;
    transition: all ease 0.3s;
    height: 0;
    overflow: hidden;
}

.custom-printing .printing-content .card:hover .card-info,
.custom-printing .printing-content .card:active .card-info {
    height: 100%;
}



.custom-printing .printing-content .card:hover .card-info ul,
.custom-printing .printing-content .card:active .card-info ul {
    display: flex;
    font-size: 1vw; /* Increase size on hover */
    opacity: 1;
    height: auto;
}

@media (max-width: 1200px){
    .custom-printing .printing-heading .printing-title {
        font-size: 5vw;
    }

    .custom-printing .printing-heading .printing-tagline {
        font-size: 2.5vw;
    }

    .custom-printing .printing-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

    }

    .custom-printing .printing-content .card .card-info h3 {
        font-size: 3vw; 
    }

    .custom-printing .printing-content .card:hover .card-info h3,
    .custom-printing .printing-content .card:active .card-info h3 {
        font-size: 3.5vw; 
    }

    .custom-printing .printing-content .card .card-info ul {
        font-size: 2.5vw; 
    }

    .custom-printing .printing-content .card:hover .card-info ul,
    .custom-printing .printing-content .card:active .card-info ul {
        font-size: 2.7vw; 
    }
}

@media (max-width: 950px) {
    .custom-printing .printing-heading .printing-title {
        font-size: 5vw;
    }

    .custom-printing .printing-heading .printing-tagline {
        font-size: 2.5vw;
    }

    .custom-printing .printing-content {
        display: grid;
        grid-template-columns: repeat(1, 1fr);

    }

    .custom-printing .printing-content .card .card-info h3 {
        font-size: 3vw; 
    }

    .custom-printing .printing-content .card:hover .card-info h3,
    .custom-printing .printing-content .card:active .card-info h3 {
        font-size: 3.5vw; 
    }

    .custom-printing .printing-content .card .card-info ul {
        font-size: 2.5vw; 
    }

    .custom-printing .printing-content .card:hover .card-info ul,
    .custom-printing .printing-content .card:active .card-info ul {
        font-size: 2.7vw; 
    }
}
