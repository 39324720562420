.products{
    padding: 15px;
    font-family: "Saira", sans-serif;
}
.products .product_heading{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Anton", sans-serif;
}
.mainHr{
    width: 250px;
    height: 1px;
    background: black;
    margin-bottom: 10px;
}
.products .product_heading .product_title{
    margin-bottom: 10px;
    font-size: 1.5em;
    text-align: center;
}
.products .product_heading .product_title span{
    color: #03B350;
}
.products .product_heading .product_tagline{
    font-size: 1.1em;
    text-align: center;
}
.products .product_card_container{
    margin-top: 30px;
    padding: 0px 20px;
}
.products .product_card_container .section{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
}
.products .product_card_container .section a{
    width: 18%;
    transition: all ease 0.2s;
    text-decoration: none;
    color: #1b1b1b;
}

.products .product_card_container .section a .product_card{
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: all ease 0.2s;
}
.products .product_card_container .section a .product_card .card_image{
    position: relative;
}
.products .product_card_container .section a .product_card .card_image .shadow{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    top: 0%;
    transition: all ease 0.2s;
}
.products .product_card_container .section a .product_card:hover{
    transform: scale(1.1);
}
.products .product_card_container .section .product_card img{
    width: 100%;
    object-fit: cover;
    transition: all ease 0.2s;
}
.products .product_card_container .section a .product_card:hover .shadow{
    background-color: transparent;
}
.products .product_card_container .section .product_card span{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
    font-weight: 500;
    text-decoration: none;
}

@media (max-width: 1200px){
    .products .product_card_container{
       display: flex;
       flex-direction: row;
    }
    .products .product_card_container .section{
        display: flex;
        flex-direction: column;
    }
    .products .product_card_container .section a{
        width: 100%;
    }
    .products .product_card_container .section .product_card{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 570px;
        align-items: center;
    }
    .products .product_card_container .section .product_card img{
        width: 90%;
        height: auto;
        object-fit: cover;
    }
    .products .product_card_container .section .product_card:hover{
        transform: scale(1);
    }
    .products .product_card_container .section .product_card .card_image .shadow{
        display: none;
    }
    .products .product_card_container .section .product_card span{
       font-size: 25px;
    }
}
@media (max-width: 950px){
    .products .product_card_container{
        display: flex;
        justify-content: space-around;
    }
    .products .product_card_container .section{
        width: 45%;
    }
    .products .product_card_container .section .product_card{
        height: auto;
    }
    .products .product_card_container .section .product_card img{
        width: 100%;
        height: auto;
    }
    .products .product_card_container .section .product_card span{
        font-size: 12px;
     }
}