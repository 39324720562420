.navbar_main{
    position: fixed;
    width: 100%;
    display: flex;
    transition: background-color 0.3s ease;
    justify-content: space-between;
    background: #0000005c;
    z-index: 1000;
    top: 0;
    font-family: "Anton", sans-serif;
}
.navbar_main.scrolled {
    background-color: black;
}
.navbar_main .nav-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
}
.navbar_main .nav-container .logo{
    display: flex;
    align-items: center;
    gap: 20px;
}
.navbar_main .nav-container .logo img{
    width: 70px;
}

.navbar_main .nav-container .nav-menu ul{
    display: flex;
    margin-right: 10px;
    gap: 20px;
}
.navbar_main .nav-container .nav-menu ul li a{
    color: #efefef;
    padding: 5px 10px;
    font-size: 16px;
    border-bottom: 3px solid transparent    ;
    transition: all ease 0.2s;
}
.navbar_main .nav-container .nav-menu ul li a:hover{
    border-bottom: 3px solid #FEC202;
}
.navbar_main .menu-icon{
    color: white;
    font-size: 18px;
    display: none;
    position: absolute;
    right: 10px;
    top: 25px;
    cursor: pointer;
}
@media (max-width: 950px) {
    .navbar_main .nav-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .navbar_main .nav-container .nav-menu{
        position: relative;
        margin-right: 0px;
        width: 100%;
    }
    .navbar_main .nav-container .nav-menu ul{
        margin-top: 30px;
        margin-right: 0;
        display: none;
        flex-direction: column;
        width: 100%;
        transition: all ease 0.2s;
    }
    .navbar_main .nav-container .nav-menu ul.open{
        display: flex;
        padding: 0;
    }
    .navbar_main .nav-container .nav-menu ul li{
        width: 100%;
        display: flex;
    }
    .navbar_main .nav-container .nav-menu ul li a{
        width: 100%;
        text-align: center;
        border: none;
    }
    .navbar_main .nav-container .nav-menu ul li a:hover{
        color: #FEC202;
        border: none;
    }
    .navbar_main .menu-icon{
        margin-right: 10px;
        display: block;
        color: white;
    }
}