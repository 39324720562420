.aboutTagline{
    padding: 0;
    margin: 0;
    height: 80vh;
    background: url('../Assets/Services_Heading_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.aboutContent{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;
    gap: 5%;
    background: linear-gradient(90deg, black, transparent);
}
.aboutContent h2{
    font-size: 5vw;
    color: white;
    font-family: "Anton", sans-serif;
}
.aboutContent h2 span{
    color: #03B350;
}
.aboutContent p{
    font-size: 1.5vw;
    color: white;
}
.aboutContent button{
    all: unset;
    height: 50px;
    width: 250px;
    text-align: center;
    border: 1px solid white;
    transition: all ease 0.2s;
    cursor: pointer;
    color: white;
    transition: all ease 0.3s;
}
.aboutContent button:hover{
    color: black;
    background-color: white;
}

@media (max-width: 1200px) {
    .aboutTagline{
        height: 70vh;
    }

    .aboutContent{
        padding: 50px;
    }

    .aboutContent h2{
        font-size: 5vh;
    }

    .aboutContent p{
        font-size: 2vw;
        color: white;
    }
}

@media (max-width: 950px){
    .aboutContent p{
        font-size: 2.5vw;
        color: white;
    }
}