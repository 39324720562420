.hero{
    height: 100vh;
    font-family: "Saira", sans-serif;
}

.hero .hero_landscape{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.hero .hero_potrait{
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.toForm{
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 200px;
    background: #ffaa01;
    font-size: 20px;
    color: white;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
}
.toForm:hover{
    text-decoration: none;
    list-style: none;
    color: white;
}
@media (max-width: 900px){
    .hero .hero_landscape{
        display: none;
    }
    .hero .hero_potrait{
        display: block;
    }
}

