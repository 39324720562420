.privateLabel {
    background-color: black;
    padding: 15px;
}

.privateLabel .privateLabelHeading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.privateLabel .privateLabelHeading .privateLabelTitle {
    font-size: 3vw;
    color: white;
}

.privateLabel .privateLabelHeading .privateLabelTagline {
    font-size: 1.2vw;
    color: white;
}

.privateContent {
    margin-top: 30px;
    display: flex;
    flex-direction: column; /* Ensure cards stack vertically */
    gap: 15px; /* Space between cards */
}

.privateContent .card {
    width: 100%; /* Full width for each card */
    height: auto; /* Allow height to adjust based on content */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border: none;
}

.privateCard1 {
    background: url('../../Components/Assets/plwp1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.privateCard2 {
    background: url('../../Components/Assets/plvp2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.privateContent .card .cardInfo {
    width: 100%;
    height: 100%;
    background: #000000c9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: white;
    padding: 20px; /* Add padding for better spacing */
}

.privateContent .card .cardInfo .cardTitle {
    font-size: 2vw;
}

.privateContent .card .cardInfo .cardTagline {
    font-size: 1.3vw;
}

.privateContent .card .cardInfo button {
    all: unset;
    height: 50px;
    width: 250px;
    text-align: center;
    border: 1px solid white;
    transition: all ease 0.2s;
    cursor: pointer;
}

.privateContent .card .cardInfo button:hover {
    color: black;
    background-color: white;
}

/* Tablet Responsiveness */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .privateLabel .privateLabelHeading .privateLabelTitle {
        font-size: 4vw;
    }

    .privateLabel .privateLabelHeading .privateLabelTagline {
        font-size: 2vw;
    }

    .privateContent .card {
        height: auto; /* Allow height to adjust based on content */
    }

    .privateContent .card .cardInfo .cardTitle {
        font-size: 3vw;
    }

    .privateContent .card .cardInfo .cardTagline {
        font-size: 2vw;
    }

    .privateContent .card .cardInfo button {
        width: 200px;
        font-size: 2vw;
    }
}

/* Mobile Responsiveness */
@media screen and (max-width: 767px) {
    .privateLabel .privateLabelHeading .privateLabelTitle {
        font-size: 6vw;
        text-align: center;
    }

    .privateLabel .privateLabelHeading .privateLabelTagline {
        font-size: 3vw;
    }

    .privateContent {
        gap: 20px; /* Increase gap between cards on mobile */
    }

    .privateContent .card {
        height: auto; /* Allow height to adjust based on content */
        min-height: 25vh; /* Minimum height for cards */
    }

    .privateContent .card .cardInfo {
        padding: 20px; /* Padding for card content */
    }

    .privateContent .card .cardInfo .cardTitle {
        font-size: 5vw;
    }

    .privateContent .card .cardInfo .cardTagline {
        font-size: 3.5vw;
    }

    .privateContent .card .cardInfo button {
        width: 80%;
        max-width: 250px;
        font-size: 3.5vw;
    }
}   