.location{
    background: #1b1b1b;
    padding: 0px 10px 50px 10px;
}
.locationHeading{
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5vw;
    color: white;
    font-family: "Anton", sans-serif;
}
.locationHeading .colorChange{
    color: #FEC202;
}

.locationContent{
    margin-top: 15px;
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 300px);
    gap: 15px;
}
.locationContent .card{
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 15px;   
    border: none;
    border-radius: 0;
}
.locationContent .card .cardIcon{
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 50px;
    background-color: green;
}
.locationContent .card .cardIcon img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.locationContent .card .cardContent{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-left: 20px;
}
.locationContent .card .cardContent .cardTitle{
    font-size: 1.5vw;
    font-weight: 600;
} 
.locationContent .card .cardContent .cardText{
    font-size: 1vw;
}

@media (max-width: 1200px) {
    .locationContent{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 300px);
    }
    .locationContent .card .cardContent{
        padding-right: 20px;
    }
    .locationContent .card .cardContent .cardTitle{
        font-size: 2.5vw;
        font-weight: 600;
    } 
    .locationContent .card .cardContent .cardText{
        font-size: 1.5vw;
    }
}
@media (max-width: 950px){
    .locationContent{
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(6, 300px);
    }
    .locationContent .card .cardContent .cardTitle{
        font-size: 3vw;
        font-weight: 600;
    } 
    .locationContent .card .cardContent .cardText{
        font-size: 2.5vw;
    }
}