.tc{
    margin-top: 80px;
    font-family: "Saira", sans-serif;
}
.tc .tc_heading{
    text-align: center;
}
.tc .tc_content{
    margin-top: 25px;
    padding: 0px 100px;
    margin-bottom: 50px;
}
.tc .tc_content .tc_section{
    width: 100%;
    padding: 0px 35px;
    margin-top: 25px;
}
.tc .tc_content .tc_section .tc_subHeading{
    display: flex;
    align-items: center;
}
.tc .tc_content .tc_section .tc_subHeading .bar{
    background: #FEC202;
    height: 3px;
    width: 20px;
    transition: all ease 0.2s;
}
.tc .tc_content .tc_section .tc_subHeading:hover .bar{
    width: 30px;
}
.tc .tc_content .tc_section .tc_subHeading span{
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
}
.tc .tc_content .tc_section .section_content{
    margin-top: 10px;
    margin-left: 40px;
    line-height: 25px;
    
}
.tc .tc_content .tc_section .section_content h6{
    margin-bottom: 5px;
    margin-top: 5px;
}
.tc .tc_content .tc_section .section_content ul{
    display: flex;
    flex-direction: column;
    gap: 5px;
}