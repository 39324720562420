.our{
    display: flex;
    flex-direction: column;
}
.our section{
    display: flex;
    padding: 5%;
}
.our .ourStory{
    background-color: white;
}
.our .ourCraft{
    background-color: black;
    color: white;
}
.our section .sectionImage{
    width: 30%;
}
.our section .sectionImage img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.our section .sectionContent{
    width: 70%;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
.our section .sectionContent .sectionHeading{
    font-size: 3vw;
    font-family: "Anton", sans-serif;
}
.our section .sectionContent .sectionText{
    font-size: 2vw;
}

@media (max-width: 1200px){
    .our .ourStory{
        flex-direction: column;
    }

    .our .ourCraft{
        flex-direction: column-reverse;
    }

    .our section .sectionImage{
        width: 100%;
    }

    .our section .sectionContent{
        width: 100%;
        margin: auto;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
    }

    .our section .sectionContent .sectionHeading{
        font-size: 5vw;
        font-weight: 600;
    }
    .our section .sectionContent .sectionText{
        font-size: 2.5vw;
    }
}

