.advantages{
    padding: 10px;
    background-color: #ececec;
}
.advantagesHeading{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Anton", sans-serif;
}
.advantagesHeading .advantagesTitle{
    font-size: 3vw;
}
.advantagesHeading .colorChange{
    color: #03B350;
}
.advantagesContent{
    margin-top: 15px;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(1, 350px);
    gap: 10px;
}
.advantagesContent .advantagesCard{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;    
    border-radius: 5px 5px 0 0;
    border-top: 5px solid #FEC202;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}
.advantagesContent .advantagesCard .logo{
    margin-top: 20px;
    height: 40%;
    width: 60%;
}
.advantagesContent .advantagesCard .logo img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.advantagesContent .advantagesCard .content{
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    gap: 20px;
    text-align: center;
    padding: 10px;
}
.advantagesContent .advantagesCard .content .contentHeading{
    font-size: 1.3vw;
    font-weight: 600;
}

@media (max-width: 1200px){
    .advantagesContent{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 450px);
        
    }
    .advantagesContent .advantagesCard .content .contentHeading{
        font-size: 2.5vw;
        font-weight: 800;
    }
}

@media (max-width: 950px){
    .advantagesContent{
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(5, 450px);
        
    }
    .advantagesContent .advantagesCard .content .contentHeading{
        font-size: 2.5vw;
        font-weight: 800;
    }
}