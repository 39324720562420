.services{
    padding: 15px;
    font-family: "Saira", sans-serif;
    margin-bottom: 20px;
}
.services .services_heading{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.services .services_heading .services_title{
    margin-bottom: 10px;
    font-size: 1.5em;
    text-align: center;
    font-family: "Anton", sans-serif;
}
.services .services_heading .services_title span{
    color: #FEC202;
}
.services .services_heading .services_tagline{
    font-size: 1.1em;
    width: 100%;
    text-align: center;
}
.services .services_card_container{
    margin-top: 30px;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.services .services_card_container .card_wrapper{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.services .services_card_container .card_wrapper .card{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.services .services_card_container .card_wrapper .card .card_img{
    background: yellow;
    width: 100%;
    height: 400px;
}
.services .services_card_container .card_wrapper .card .card_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.services .services_card_container .card_wrapper .card .card_content{
    background-image: url('../Assets/Card_bg.png');
    color: white;
    padding: 10px 10px 25px 30px;
    height: 225px;
}
.services .services_card_container .card_wrapper .card .card_content .card_heading{
    width: 70%;
    margin-top: 20px;
    margin-bottom: 15px;
    font-family: "Anton", sans-serif;
}
.services .services_card_container .card_wrapper .card .card_content .card_heading span{
    font-size: 1.4em;
    line-height: 30px;
    font-weight: 600;
    font-family: "Saira", sans-serif;
}
.services .services_card_container .card_wrapper .card .card_content hr{
    height: 1px;
    background: white;
    width: 130px;
    margin-bottom: 15px;
}
.services .services_card_container .card_wrapper .card .card_content .card_text{
    display: flex;
    align-items: center;
    text-align: justify;
}
.services .services_card_container .card_wrapper .card .card_content .card_text span{
    width: 90%;
}
.services .services_card_container .card_wrapper a{
    width: 100%;
}
.services .services_card_container .card_wrapper button{
    margin-top: 20px;
    width: 100%;
    height: 50px;
    border: 1px solid #000000c9;
    background-color: white;
    color: #000000d1;
    font-size: 1.2em;
    transition: all ease 0.2s;
}
.services .services_card_container .card_wrapper button:hover{
    border: 1px solid #000000c9;
    background-color: #000000c9;
    color: white;
}
@media (max-width: 1200px){
    .services .services_card_container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .services .services_card_container .card_wrapper{
        width: 100%;
        margin-bottom: 45px;
    }
}