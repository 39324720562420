.clients{
    display: flex;
    flex-direction: row;
    
}
.client_content{
    width: 40%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    align-items: center;
    background: #EFEFEF;
}
.clients .client_content .client_heading{
    margin-bottom: 20px;
    font-size: 2.2em;
    font-weight: 600;
    font-family: "Anton", sans-serif;
}
.clients .client_content .client_text{
    text-align: justify;
    width: 90%;
    text-align: left;
    font-size: 0.9em;
    line-height: 25px;
    font-family: "Saira", sans-serif;
}
.clients .client_content button{
    margin-top: 50px;
    background: none;
    border: 1px solid #000000;
    background: #000000;
    color: white;
    width: 180px;
    height: 40px;
    font-size: 1em;
    transition: all ease 0.2s;
    font-family: "Saira", sans-serif;
}
.clients .client_content button:hover{
    background-color: transparent;
    color: black;
}

.clients .client_logos{
    display: flex;
    width: 60%;
    background: #121212;
    flex-direction: column;
    justify-content: space-evenly;
}
.clients .client_logos .client_row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 30px;
}
.clients .client_logos .client_row img{
    width: 150px;
    height: 150px;
    object-fit: cover;
}
@media (max-width: 1200px){
    .clients{
        flex-direction: column;
    }
    .client_content{
        padding: 40px 0px;
        width: 100%;
    }
    .clients .client_logos{
        width: 100%;
    }
    .clients .client_logos .client_row img{
        width: 100px;
        height: 100px;
    }
    .clients .client_content .client_heading{
        margin: 0;
    }
    .clients .client_content .client_text{
        width: 80%;
    }
}
@media (max-width: 1000px){
    .clients .client_logos{
        width: 100%;
        flex-direction: row;
    }
    .clients .client_logos .client_row{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 15px;
    }
    .clients .client_logos .client_row img{
        width: 100px;
        height: 100px;
    }
}

