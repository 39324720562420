.capacityHeading{
    padding: 15px;
    display: flex;
    justify-content : center;
}
.capacityHeading .capacityTitle{
    font-size: 3vw;
    font-family: "Anton", sans-serif;
}
.capacityContent{
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 300px);
    gap: 20px;   
}
.capacityContent .capacityCard{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.capacityContent .capacityCard .cardImage{
    height: 65%;
    width: 100%;
}
.capacityContent .capacityCard .cardImage img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.capacityContent .capacityCard .cardContent{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

@media (max-width: 1200px){
    .capacityContent{
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(3, 300px);
    }
}

@media (max-width: 950px){
    .capacityContent{
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(3, 300px);
    }

    .cardHeading{
        font-size: 3.5vw;
    }
}