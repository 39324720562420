.customTailoring {
    height: auto;
    display: flex;
    flex-direction: column;
}

.customTailoring section {
    width: 100%;
}

.customTailoring .sectionHeading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: #111111;
    padding: 40px 20px;
}

.customTailoring .sectionHeading span {
    color: #ededed;
}

.customTailoring .sectionHeading .heading {
    font-size: 8vw;
    text-align: center;
}

.customTailoring .sectionHeading .tagline {
    font-size: 4vw;
    margin-top: -15px;
    text-align: center;
}

.customTailoring .sectionHeading button {
    background-color: transparent;
    color: #ededed;
    border: 1px solid #ededed;
    height: 40px;
    width: 80%;
    max-width: 250px;
    transition: all ease 0.3s;
}

.customTailoring .sectionHeading button:hover {
    color: #111111;
    background-color: #ededed;
}

.customTailoring .cardContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
}

.customTailoring .cardContainer .card {
    border: none;
    border-radius: 0;
    height: 50vh;
}

.customTailoring .cardContainer .card .cardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 15px;
    text-align: center;
    background: linear-gradient(180deg, #000000, transparent);
    color: white;
    gap: 15px;
}

.cardHeading {
    font-size: 6vw;
}

.cardTagline {
    font-size: 4vw;
}

.card1 {
    background: url('../../Components/Assets/customImage1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card2 {
    background: url('../../Components/Assets/customImage2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card3 {
    background: url('../../Components/Assets/customImage3.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card4 {
    background: url('../../Components/Assets/customImage4.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* Tablet view */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .customTailoring .cardContainer {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    .customTailoring .sectionHeading .heading {
        font-size: 6vw;
    }

    .customTailoring .sectionHeading .tagline {
        font-size: 2.5vw;
    }

    .cardHeading {
        font-size: 3vw;
    }

    .cardTagline {
        font-size: 2vw;
    }
}

/* Desktop view */
@media screen and (min-width: 1024px) {
    .customTailoring {
        height: 70vh;
        flex-direction: row;
    }

    .customTailoring section {
        width: 50%;
    }

    .customTailoring .sectionHeading {
        padding: 0;
    }

    .customTailoring .sectionHeading .heading {
        font-size: 5vw;
    }

    .customTailoring .sectionHeading .tagline {
        font-size: 1.5vw;
        margin-top: -30px;
    }

    .customTailoring .sectionHeading button {
        width: 250px;
    }

    .customTailoring .cardContainer {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 50%);
    }

    .customTailoring .cardContainer .card {
        height: 100%;
    }

    .cardHeading {
        font-size: 1.5vw;
    }

    .cardTagline {
        font-size: 1.1vw;
    }
}