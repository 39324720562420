.accerrories{
    margin-bottom: 15px;
}
.accessoriesHeadeing{
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.accessoriesHeadeing .heading{
    color: black;
    font-size: 3vw;
    font-weight: 600;
}
.accessoriesHeadeing .tagline{
    color: black;
    font-size: 1.2vw;
    font-weight: 400;
}
.accessoriesContent{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.accessoriesContent .card{
    width: 45%;
    height: 70vh;
    border: none;
    border-radius: 0;
}
.accessoriesContent .card .cardImg{
    height: 60%;
    width: 100%;
}
.accessoriesContent .card .cardImg img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.accessoriesContent .card .cardContent{
    background-color: black;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    color: white;
}
/* Mobile Responsiveness */
@media screen and (max-width: 767px) {
    .accessoriesContent {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center cards */
        justify-content: center; /* Center cards */
    }

    .accessoriesContent .card {
        width: 100%; 
    }
}