.why-printing .why-heading {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0c0c0c;
}

.why-heading span {
    font-size: 5vw;
    color: white;
}

.why-heading .color-change {
    color: #FEC202;
}

.why-printing .why-printing-content {
    display: flex;
    flex-direction: column;
}

.why-printing .why-printing-content .printing-card {
    display: flex;
    align-items: flex-end;
    height: 50vh;
    position: relative;
}

.printing_card1 {
    background: url('../../Components/Assets/printingService1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.printing_card2 {
    background: url('../../Components/Assets/printingService2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.printing_card3 {
    background: url('../../Components/Assets/printingService3.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.printing_card4 {
    background: url('../../Components/Assets/printingService4.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.why-printing .why-printing-content .printing-card .printing-card-info {
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    justify-content: center;
    background: rgba(13, 13, 13, 0.73);
    transition: all ease 0.3s;
    position: absolute;
    bottom: 0;
}

.why-printing .why-printing-content .printing-card .printing-card-info .printing-card-title {
    font-size: 5vw;
    color: white;
}

.why-printing .why-printing-content .printing-card .printing-card-info .printing-card-tagline {
    color: white;
    font-size: 3.5vw;
    text-align: center;
    display: none;
    padding: 0 10px;
}

.why-printing .why-printing-content .printing-card:hover .printing-card-info,
.why-printing .why-printing-content .printing-card:active .printing-card-info {
    height: 100%;
}

.why-printing .why-printing-content .printing-card:hover .printing-card-info .printing-card-tagline,
.why-printing .why-printing-content .printing-card:active .printing-card-info .printing-card-tagline {
    display: block;
}

/* Tablet view */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .why-heading span {
        font-size: 4vw;
    }

    .why-printing .why-printing-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 50vh);
    }

    .why-printing .why-printing-content .printing-card .printing-card-info .printing-card-title {
        font-size: 3vw;
    }

    .why-printing .why-printing-content .printing-card .printing-card-info .printing-card-tagline {
        font-size: 2vw;
    }
}

/* Desktop view */
@media screen and (min-width: 1024px) {
    .why-heading span {
        font-size: 3vw;
    }

    .why-printing .why-printing-content {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: repeat(2, 50vh);
    }

    .why-printing .why-printing-content .printing-card .printing-card-info .printing-card-title {
        font-size: 2vw;
    }

    .why-printing .why-printing-content .printing-card .printing-card-info .printing-card-tagline {
        font-size: 1.2vw;
    }
}