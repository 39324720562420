.servicesTagline {
    padding: 0;
    margin: 0;
    height: 70vh;
    background: url('../Assets/Services_Heading_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .servicesContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;
    gap: 5%;
    background: linear-gradient(90deg, black, transparent);
  }
  
  .servicesContent h2 {
    font-size: 5vw;
    color: white;
  }
  
  .servicesContent h2 span {
    color: #03B350;
  }
  
  .servicesContent p {
    font-size: 2vw;
    color: white;
  }
  
  @media (max-width: 768px) {
    /* Tablet styles */
    .servicesContent {
      padding-left: 3%;
      gap: 3%;
    }
  
    .servicesContent h2 {
      font-size: 6vw;
    }
  
    .servicesContent p {
      font-size: 3vw;
    }
  }
  
  @media (max-width: 480px) {
    /* Mobile styles */
    .servicesTagline {
      height: 50vh;
      background: url('../Assets/Services_Heading_bg.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  
    .servicesContent {
      padding: 8%;
      gap: 2%;
    }
  
    .servicesContent h2 {
      font-size: 8vw;
    }
  
    .servicesContent p {
      font-size: 4vw;
    }
  }