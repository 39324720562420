.promise{
    padding: 15px;
}
.promise .promiseHeading{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;
}
.promise .promiseHeading .promiseTitle{
    font-size: 3vw;
    font-family: "Anton", sans-serif;
}
.promise .promiseHeading .promiseTagline{
    font-size: 1.5vw;
}
.promiseContent{
    display: grid;
    gap: 10px;
    justify-content: center;
    grid-template-columns: repeat(3, 350px);
    grid-template-rows: repeat(1, 500px);
    margin-top: 15px;
}
.promiseContent .card{
    display: flex;
    flex-direction: column;
    padding: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.promiseContent .card .cardImage{
    width: 100%;
    height: 60%;
}
.promiseContent .card .cardImage img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.promiseContent .card .cardContent{
    margin-top: 15px;
    height: 40%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 15px;
}
.promiseContent .card .cardContent .cardTitle{
    font-size: 1.5vw;
    font-weight: 600;
}
.promiseContent .card .cardContent .cardTagline{
    font-size: 1.vw;
}

@media (max-width: 1200px){
    .promise .promiseHeading .promiseTitle{
        font-size: 4vw;
        font-weight: 600;
    }
    .promise .promiseHeading .promiseTagline{
        font-size: 1.5vw;
    }
    .promiseContent{
        grid-template-columns: repeat(2, 350px);
        grid-template-rows: repeat(2, 500px);
    }
}

@media (max-width: 950px){
    .promiseContent{
        grid-template-columns: repeat(1, 350px);
        grid-template-rows: repeat(3, 500px);
    }
}