.productContent{  
    margin-top: 30px;  
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-family: "Saira", sans-serif;
}
.button_container button{
    background: transparent;
    border: 1px solid #000000;
    padding: 10px 20px;
    font-size: 1.2em;
    margin-bottom: 50px;
}
.content_heading{
    font-size: 3em;
    font-weight: 700;
    text-transform: uppercase;
}
.content_sub_heading{
    margin-top: 40px;
    font-size: 1.3em;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}
.content_tagline{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    margin-bottom: 50px;
    font-weight: bold;
    color: #292929;
}
.content_tagline .bar{
    width: 7px;
    background-color: #fec202;
    height: 35px;
    margin-right: 10px;
}
.content_text{
    font-size: 1.2em;
}

.two_content{
    display: flex;
    justify-content: space-evenly;
    gap: 100px;

}
.two_content .content{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.two_content .content .content_sub_heading{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.two_content .subheading_tagline{
    font-size: 14px;
    font-weight: normal;
}
.content_text ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    font-size: 14px;
}
.content_text ul li{
    list-style: disc;
}
.redirect_button{
    width: 100%;
    padding: 10px 75px;
    background-color: black;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}
.redirect_button span{
    font-size: 2em;
}
.redirect_button a button{
    background-color: #03B205;
    color: white;
    width: 200px;
    height: 50px;
    border-radius: 5px;
    font-size: 1.2em;
}
@media (max-width: 950px){
    .redirect_button{
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: 25px;
    }
}
