.corporateServices .corporateHeading {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.corporateHeading .corporateTitle {
    font-size: 3vw;
    font-weight: 600;
}

.corporateHeading .corporateTitle .colorChange {
    color: #FEC202;
}

.corporateHeading .corporateTagline {
    font-size: 1.2vw;
}

.corporateContent {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(1, 70vh);
}

.corporateContent .corporateCard {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.corporateCard1 {
    background: url('../../Components/Assets/corporateService1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.corporateCard2 {
    background: url('../../Components/Assets/corporateService2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.corporateContent .corporateCard .corporateCardInfo {
    width: 100%;
    padding: 20%;
    text-align: center;
    background-color: #000000d3;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.corporateContent .corporateCard .corporateCardInfo .cardInfoTitle {
    color: white;
    font-size: 1.5vw;
}

.corporateContent .corporateCard .corporateCardInfo .cardInfoTagline {
    color: white;
    font-size: 1vw;
}

.corporateContent .corporateCard .corporateCardInfo .cardBtn {
    all: unset;
    padding: 10px 0;
    width: 250px;
    border: 1px solid white;
    color: white;
    margin-top: 30px;
    transition: all ease 0.2s;
}

.corporateContent .corporateCard .corporateCardInfo .cardBtn:hover {
    background: white;
    color: black;
}

/* Tablet Responsiveness */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .corporateContent {
        grid-template-columns: repeat(1, 100%);
        grid-template-rows: repeat(2, auto);
    }

    .corporateHeading .corporateTitle {
        font-size: 4vw;
    }

    .corporateHeading .corporateTagline {
        font-size: 2vw;
    }

    .corporateContent .corporateCard .corporateCardInfo .cardInfoTitle {
        font-size: 2.5vw;
    }

    .corporateContent .corporateCard .corporateCardInfo .cardInfoTagline {
        font-size: 1.8vw;
    }
}

/* Mobile Responsiveness */
@media screen and (max-width: 767px) {
    .corporateContent {
        grid-template-columns: 100%;
        grid-template-rows: repeat(2, auto);
    }

    .corporateHeading .corporateTitle {
        font-size: 6vw;
    }

    .corporateHeading .corporateTagline {
        font-size: 3vw;
    }

    .corporateContent .corporateCard {
        height: 50vh;
    }

    .corporateContent .corporateCard .corporateCardInfo {
        height: 100%;
        padding: 10%;
    }

    .corporateContent .corporateCard .corporateCardInfo .cardInfoTitle {
        font-size: 4.5vw;
    }

    .corporateContent .corporateCard .corporateCardInfo .cardInfoTagline {
        font-size: 3vw;
    }

    .corporateContent .corporateCard .corporateCardInfo .cardBtn {
        width: 80%;
        font-size: 3vw;
    }
}