@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Saira:ital,wght@0,100..900;1,100..900&display=swap');
*{
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Saira", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
