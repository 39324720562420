.footer{
    position: relative;
    background: rgba(25,21,21,1);
    display: flex;
    flex-direction: column;
    font-family: "Saira", sans-serif;
}
.footer span{
    color: white;
    font-size: 1.1em;   
}

.icons a{
    color: white;
}
 i{
    margin-right: 20px;
    font-size: 22px;
}
.footer-info{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.footer .footer-info .coloumn{
    width: calc(100%/4);
    padding: 25px 50px 0px 50px;
}
.footer .footer-info .coloumn .footer-heading{
    margin-bottom: 15px;
    font-family: "Anton", sans-serif;
}

.footer .footer-info .coloumn .address, .phone, .available, .info{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;
}
.footer .footer-info .coloumn .info a{
    text-decoration: none;
}
.footer .copyrights{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
}

.footer .copyrights span{
    color: #7a7a7a;
}

@media (max-width: 950px) {
    .footer-info{
        flex-direction: column;
    }
    .footer .footer-info .coloumn{
        width: 100%;
        margin-bottom: 15px;
    }
}
