.customization{
    padding: 5%;
    background-color:#efefef;
}
.customization .customizationHeading{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.customization .customizationHeading .heading{
    font-size: 3vw;
    font-family: "Anton", sans-serif;
}
.customization .customizationHeading .tagline{
    font-size: 1.3vw;
    font-weight: 400;
}
.customizationContent{
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(1, 350px);
    gap: 10px;
}
.customizationContent .card{
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
}
.customizationContent .card .cardImage{
    height: 45%;
    width: 100%;
}
.customizationContent .card .cardImage img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.customizationContent .card .cardContent{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 15px;
    line-height: 25px;
}
.customizationContent .card .cardContent .cardHeading{
    margin-top: 10px;
    font-weight: 600;
    font-size: 20px;
}

@media (max-width: 1200px) {
    .customizationContent{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 350px);
    }
}

@media (max-width: 950px) {
    .customizationContent{
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(5, 350px);
    }
}