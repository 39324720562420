.contact_container{
    padding: 70px 130px;
    display: flex;
}
.contact_address{
    width: 45%;
    display: flex;
    flex-direction: column;
}
.contact_address .aus_address, .ind_adress{
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}
.contact_address .aus_address .aus_heading{
    display: flex;
    height: 20px;
    align-items: center;
    margin-bottom: 20px;
    font-family: "Anton", sans-serif;
}

.ind_heading{
    display: flex;
    height: 20px;
    align-items: center;
    margin-bottom: 20px;
    font-family: "Anton", sans-serif;
}
.block{
    width: 5px; 
    height: 40px;
    background: rgba(254,187,21,1);
    margin-right: 15px;
}

.aus_add, .ind_add{
    display: flex;
    flex-direction: column;
    gap: 7px;
}
.form_heading{
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    font-family: "Anton", sans-serif;
}
.contact_form{
    width: 55%;
    display: flex;
    flex-direction: column;
}
.row{
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}
.row div{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.row .text-row{
    width: 100%;
}
.row div label{
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: 600;
}
.row div input,select{
    width: 92%;
    height: 30px;
    border-radius: 3px;
    border: 1px solid #4242425e;
    padding-left: 10px;
}
.text-row .upload{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
} 
.text-row .upload span{
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 20px;
}
.text-row .upload input{
    display: none;
}
.text-row .upload label{
    color: white;
    background-color: #333333;
    border: 1px solid #ffffff;
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s ease;
    border-radius: 3px;
}
.text-row .upload label:hover{
    color: #333333;
    background-color: white;
    border: 1px solid #333333;
}
.text-row button{
    height: 50px;
    width: 150px;
    border: none;
    color: white;
    border-radius: 3px;
    background-color: rgba(11,141,71,1);
    margin-top: 15px;
}
textarea{
    border: 1px solid black;
}
.row div input:focus{
    outline: none;
    border: 1px solid #66afe9;
    box-shadow: #66aee99f 0px 7px 29px 0px;;
}
@media (max-width: 1200px){
    .contact_container{
        flex-direction: column;
        padding: 50px;
    }
    .contact_container .contact_address{
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;

    }
    .contact_form{
        width: 100%;
        padding: 0 10%;
    }
    @media (max-width: 750px){
        .contact_container .contact_address{
            width: 100%;
            flex-direction: column;
            justify-content: space-evenly;
            margin-bottom: 50px;
        }
        .row{
            flex-direction: column;
        }
        .row div{
            width: 100%;
        }
        input, select, textarea{
            width: 100%;
            margin-bottom: 30px;
        }
        .row div input,select{
            width: 100%;
        }
    }
}