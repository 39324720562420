@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.team .team_heading{
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
hr{
    width: 250px;
    height: 1px;
    background: black;
    margin-bottom: 10px;
}
.team .team_heading .team_title{
    margin-bottom: 10px;
    font-size: 1.5em;
}
.team .team_heading .team_title span{
    color: #03B350;
}
.team .team_heading .team_tagline{
    font-size: 0.9em;
    width: 100%;
    text-align: center;
}
.team_container{
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}
.team_container .row{
    margin: 50px 0 0 0  ;
    display: flex;
    justify-content: space-evenly;
}
.team .team_container .row .member{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 25%;
}
.team .team_container .row .member .member_img{
    width: 226px;
    height: 226px;
    border-radius: 113px;
}
.team .team_container .row .member .member_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 223px;
}
.team .team_container .row .member .member_name{
    text-align: center;
    font-size: 1.3em;
}
.team .team_container .row .member .member_designation{
    text-align: center;
    font-family: "Alegreya", serif;
    font-style: italic;
}
@media (max-width: 1200px){
    .team .team_container{
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
    .team .team_container .row{
        flex-direction: column;
        align-items: center;
    }
}
@media (max-width: 950px){
    .team .team_container{
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .team .team_container .row{
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }
}