.aboutSustain{
    height: 50vh;
    background: url('../../Components/Assets/about_sustain.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.sustainContainer{
    background-color: hsla(0, 0%, 0%, 0.726);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 5%;
    color: white;
}
.sustainContainer .aboutSustainHeading{
    font-size: 2.5vw;
    font-family: "Anton", sans-serif;
}
.sustainContainer .colorChange{
    color: #03B350;
}
.sustainContainer .aboutSustainText{
    font-size: 1.5vw;
}
.sustainContainer button{
    all: unset;
    height: 50px;
    width: 250px;
    text-align: center;
    border: 1px solid white;
    transition: all ease 0.2s;
    cursor: pointer;
    transition: all ease 0.2s;
}
.sustainContainer button:hover{
    color: black;
    background-color: white;
}

@media (max-width: 1200px){
    .aboutSustain{
        height: 40vh;
    }
    .sustainContainer .aboutSustainHeading{
        font-size: 3vw;
        font-weight: 600;
    }
    .sustainContainer .aboutSustainText{
        font-size: 1.5vw;
    }
}

@media (max-width: 950px){
    .aboutSustain{
        height: 40vh;
    }
    .sustainContainer .aboutSustainHeading{
        font-size: 4vw;
        font-weight: 600;
    }
    .sustainContainer .aboutSustainText{
        font-size: 2vw;
    }
}