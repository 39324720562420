.about_content{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Saira", sans-serif;
}
.about_content .about_heading{
    width: 70%;
    text-align: center;
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 30px;
}
.about_content .about_text{
    text-align: justify;
    font-size: 1.2em;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: 75%;
}