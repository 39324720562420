.bannerCarousel img{
    height: 100vh;
    object-fit: cover;
    object-position: left;
}
.carouselCaption{
    top: 50%;
    left: 40%;
    transform: translateY(-50%);
    text-align: left;
}
.carouselContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
}
.bannerHeading{
    font-family: "Anton", sans-serif;
    font-size: 3.3vw;
    font-weight: 500;
}
.bannerText{
    font-size: 1.3vw;
    font-family: "Saira", sans-serif;
    font-weight: 600;
}
.carouselContent button{
    font-family: "Anton", sans-serif;
    background-color: #FEC202;
    padding: 1vw 3vw;
    color: white;
    border-radius: 3px;
    font-size: 3vh;
}

@media(max-width: 1750px){
    .carouselCaption{
        top: 50%;
        left: 42%;
        transform: translateY(-50%);
        text-align: left;
    }  
}
@media(max-width: 1600px){
    .carouselCaption{
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        text-align: left;
    }  
}

@media(max-width: 1450px){
    .carouselCaption{
        top: 50%;
        left: 55%;
        transform: translateY(-50%);
        text-align: left;
    }  
}


@media (max-width: 1200px){
    .carouselCaption{
        padding: 0;
        top: 50%;
        left: 50%;
        right: auto;
        transform: translate(-50%,-50%);
        height: 100%;
        width: 100%;
    }
    .carouselContent{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #0000005c;
        width: 100%;
        height: 100%;
    }
    .bannerHeading{
        text-align: center;
    }
    .bannerText{
        text-align: center;
        font-size: 3vw;
    }
    
}
@media (max-width: 600px){
    .bannerCarousel img{
        height: 100vh;
        object-fit: cover;
        object-position: center;
    }
    .carouselCaption{
        padding: 0;
        top: 50%;
        left: 50%;
        right: auto;
        transform: translate(-50%,-50%);
        height: 100%;
        width: 100%;
    }
    .carouselContent{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #0000005c;
        width: 100%;
        height: 100%;
    }
    .bannerHeading{
        font-size: 9vw;
        font-weight: 900;
    }
    .bannerText{
        font-size: 3vw;
        font-weight: 600;
    }
    .carouselContent button{
        background-color: #FEC202;
        padding: 3vw 6vw;
        color: white;
        border-radius: 3px;
        font-size: vh;
        font-weight: 900;
    }
}

